import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils';

export const getPaymentsListAvailable = async () => {
    const { data } = await Api.get('/api/writer/pay-method/list-available')
    return data
}

export const getPaymentsListConnected = async () => {
    const { data } = await Api.get('/api/writer/pay-method/list-connected', {}, {}, false)
    return data
}

export const paymentWireCreateOrUpdate = async (payload) => {
    const { data } = await Api.post('/api/writer/pay-method/wire/create-or-update', removeEmptyParamsFromRequest({ ...payload }))
    return data
}

export const paymentMpesaCreateOrUpdate = async (payload) => {
    const { data } = await Api.post('/api/writer/pay-method/mpesa/create-or-update', removeEmptyParamsFromRequest({ ...payload }))
    return data
}

export const paymentPayoneerCreateOrUpdate = async (payload) => {
    const { data } = await Api.post('/api/writer/pay-method/payoneer/update', removeEmptyParamsFromRequest({ ...payload }))
    return data
}

export const deleteWithdrawalMethod = async (payload) => {
    const { data } = await Api.delete('/api/writer/pay-method/delete', removeEmptyParamsFromRequest({ ...payload }))
    return data
}

export const getStatusPayoneer = async () => {
    const { data } = await Api.get('/api/writer/pay-method/payoneer/status', {}, {}, false)
    return data
}

export const createPayoneer = async () => {
    const { data } = await Api.post('/api/writer/pay-method/payoneer/create')
    return data
}

export const getLinkPayoneer = async (payload) => {
    const { data } = await Api.get('/api/writer/pay-method/payoneer/link', removeEmptyParamsFromRequest({ ...payload }), {}, false)
    return data
}
