<template>
    <div
        class="header-content"
        :class="headerInfo.className"
    >
        <div class="wrapper wrapper-header">
            <breadcrumbs :steps="headerInfo.steps" />
            <div class="header-content__info">
                <div class="header-content__title">
                    <h1>
                        {{ headerInfo.title }}
                    </h1>
                    <span
                        v-if="headerInfo.counter"
                        class="header-content__counter"
                    >
                        {{ headerInfo.counter }}
                    </span>
                    <span class="header-content__subtitle">
                        <span class="header-content__subtitle-text">{{ headerInfo.subtitle }}</span>
                        <span class="header-content__subtitle-count"> {{ headerInfo.subTitleCount }}</span>
                    </span>
                </div>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
    name: 'HeaderContent',
    components: {
        Breadcrumbs
    },
    props: {
        headerInfo: {
            type: Object,
            require: true
        }
    }
}
</script>

<style lang="scss" scoped>
.header-content {
    padding: 90px 0;
    &__title {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 56px;
        color: $white;
    }
    &__subtitle {
        font-size: 20px;
        font-weight: 100;
        padding: 5px 10px;
        align-self: end;
    }
    &__subtitle-text{
        &:before {
            content: '/';
            margin-right: 3px;
        }
        &:empty {
            display: none;
        }
    }

    &__counter {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        background: #1161E0;
        border-radius: 4px;
        font-size: 20px;
        margin-left: 20px;
        line-height: 24px;
    }
    &__credits, &__bookmarks {
        background: url('~@/assets/img/bg-writing.jpg') center no-repeat;
        background-size: cover;
    }
    &__documents{
        background: url('~@/assets/img/bg-book.jpg') center no-repeat;
        background-size: cover;
    }
    &__orders {
        background: url('~@/assets/img/bg-notebook.jpg') center no-repeat;
        background-size: cover;
    }
    &__account-setting {
        background: url('~@/assets/img/bg-book.jpg') center no-repeat;
        background-size: cover;
    }
    &__addition {
        display: flex;
        align-items: center;
        color: $white;
        padding-top: 20px;
    }
    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__paid, &__balance {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        font-size: 24px;
        margin-right: 25px;
    }
    &__agreed {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        margin-right: 25px;
        &::before {
            content: '';
            width: 16px;
            height: 16px;
            display: block;
            mask-image: url('~@/assets/img/svg/check.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
            background: $white;
            margin-right: 10px;
        }
    }
}
@media (max-width: 992px) {
    .header-content {
        padding: 45px 0;
        &__info {
            flex-wrap: wrap;
        }
        &__paid, &__balance, &__report, &__agreed {
            margin-bottom: 25px;
        }
        &__credits {
            .header-content__addition {
                align-items: center;
            }
        }
        &__tickets {
            padding: 45px 0 110px;
        }
    }
}

@media (max-width: 768px) {
    .header-content {
        &__account-setting {
            margin-bottom: 45px;
        }
    }
}

@media (max-width: 576px) {
    .header-content{
        &__title {
            font-size: 24px;
        }
        &__subtitle{
            font-size: 13px;
            padding: 3px 10px;
            &:before {
                margin-right: 5px;
            }
        }
        &__account-setting {
            padding: 20px 0;
            margin-bottom: 20px;
        }
        &__tickets {
            padding: 45px 0 80px;
            .header-content__addition {
                justify-content: space-between;
                width: 100%;
            }
            .header-content__btn {
                order: -1;
            }
        }
    }
}
</style>
