<template>
    <div>
        <alert-in-development
            v-if="error"
            message="Service is temporarily unavailable"
        />
        <template v-else>
            <div
                v-if="loading"
                class="radius-loader--wrapper"
            >
                <div
                    class="background-for-radius-loader"
                >
                    <img
                        src="@/assets/img/svg/animation/LoaderRadius.svg"
                        alt="loader"
                    >
                </div>
            </div>
            <div
                v-else
                class="payments__list-item"
            >
                <div
                    v-if="id"
                    class="payments__list-item-inner"
                >
                    <div>
                        <p class="payments__list-item-label">
                            Payoneer account number:
                        </p>
                        <div class="payments__list-item-value">
                            {{ id }}
                        </div>
                    </div>
                    <div>
                        <p class="payments__list-item-label">
                            Payoneer system status:
                        </p>
                        <div :class="`payments__list-item-badge payoneer-status--${status.toLowerCase()}`">
                            {{ status }}
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="payoneer-connect"
                >
                    <p class="payoneer-title">
                        Connect an existing or register a new account Payoneer:
                    </p>
                    <div class="payoneer-btns">
                        <custom-button
                            default
                            class="btn-base btn-main button"
                            :loading="existingLoading"
                            @on-btn-click="connectExisting"
                        >
                            <span>
                                Link existing card
                            </span>
                        </custom-button>
                        <custom-button
                            class="btn-base btn-border button"
                            bordered
                            hover
                            :loading="registerLoading"
                            @on-btn-click="register"
                        >
                            <span class="profile-info__reset-button-icon">
                                Register a new
                            </span>
                        </custom-button>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import AlertInDevelopment from '@/components/alerts/AlertInDevelopment.vue'
import { eventBus } from '@/helpers/event-bus';
import {
    getStatusPayoneer,
    getLinkPayoneer
} from '@/services/payments';

export default {
    name: 'WizardPaymentPayoneer',
    components: {
        AlertInDevelopment
    },
    data() {
        return {
            id: null,
            loading: true,
            existingLoading: false,
            registerLoading: false,
            status_code: null,
            status: null,
            error: false
        }
    },
    async created() {
        this.loading = true
        await this.getStatusPayoneer()
        this.loading = false
    },
    methods: {
        async getStatusPayoneer() {
            try {
                const { result } = await getStatusPayoneer()
                this.id = result?.account_id
                this.status_code = result?.status?.type
                this.status = result?.status?.description
                this.$emit('canConnected', this.status_code === 1)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
                this.error = true
            }
        },
        async getLinkPayoneer(payload) {
            try {
                const { result } = await getLinkPayoneer(payload)
                window.location.href = result.registration_link
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async connectExisting() {
            this.existingLoading = true
            await this.getLinkPayoneer({
                already_have_an_account: 1
            })
            this.existingLoading = false
        },
        async register() {
            this.registerLoading = true
            await this.getLinkPayoneer()
            this.registerLoading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.payoneer {
    &-connect {
        display: grid;
        gap: 20px;
    }
    &-title {
        font-size: 20px;
        color: #181818;
    }
    &-btns {
        display: flex;
        gap: 30px;
    }
}
</style>
