<template>
    <div class="alert-development__container">
        <div class="alert-development__block">
            <alert-circle-outline />
            <div class="alert-development__message">
                Coming soon!<br><br>
                {{ message }}
            </div>
        </div>
    </div>
</template>

<script>
import AlertCircleOutline from 'mdi-vue/AlertCircleOutline'

export default {
    name: 'AlertInDevelopment',
    components: {
        AlertCircleOutline
    },
    props: {
        message: {
            type: String,
            required: false,
            default: 'For now, please request payouts by creating a support ticket (choose complaint, and then payout)'
        }
    }
}
</script>

<style lang="scss">
.alert-development {
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__block {
        padding: 30px 40px;
        background: #f8d7da;
        color: #842029;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        @media (max-width: 768px) {
            padding: 15px 10px;
        }
    }
}
</style>
