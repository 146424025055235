<template lang="html">
    <div>
        <ul class="breadcrumbs">
            <template v-for="(step, index) in steps">
                <li
                    :key="index"
                    class="breadcrumbs-item"
                    :class="steps.length - 1 === index ? 'bc-current' : ''"
                >
                    <router-link
                        :to="step.url"
                    >
                        {{ step.title }}
                    </router-link>
                    <ChevronRightIcon v-if="steps.length - 1 != index" />
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import ChevronRightIcon from 'mdi-vue/ChevronRight'

export default {
    name: 'Breadcrumbs',
    components: {
        ChevronRightIcon
    },
    props: {
        steps: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss">
.breadcrumbs--item {
    display: flex;
    align-items: center;
}
.bc-wrapper {
    .wrapper {
        flex-wrap: wrap;
        .breadcrumbs-item{
            width: 100%;
            &:nth-child(2) {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
@media screen and (max-width: 600px){
    .bc-wrapper {
        padding-bottom: 100px;
        .wrapper {
            .breadcrumbs-item {
                &:nth-child(2) {
                    flex-wrap: wrap;
                    padding-right: 0 !important;
                }
            }
        }
    }
}
@media (max-width: 480px) {
    .breadcrumbs-item a{
        font-size: 16px;
    }
}
</style>
