<template lang="html">
    <main-layout>
        <template #content>
            <header-content :header-info="headerInfo" />
            <div class="profile wrapper">
                <div class="profile-header-block">
                    <div
                        v-if="loading"
                        class="radius-loader--wrapper"
                    >
                        <div
                            class="background-for-radius-loader"
                        >
                            <img
                                src="@/assets/img/svg/animation/LoaderRadius.svg"
                                alt="loader"
                            >
                        </div>
                    </div>
                    <div
                        v-else
                        class="payments-add__container"
                    >
                        <form-select
                            v-if="!!types.length"
                            :value="selectedType"
                            :field.sync="selectedType"
                            :value-select="selectedType"
                            :items="types"
                            class="payments-add__select"
                            name="pay_method"
                            label="Payment Method"
                            @input="selectMethod"
                        />
                        <wizard-payment-form
                            v-if="selectedType === 'wire'"
                            :ref="selectedType"
                            :fields="fields[selectedType]"
                            :form-data="formData[selectedType]"
                            @update="onUpdate($event, selectedType)"
                        />
                        <wizard-payment-payoneer
                            v-if="selectedType === 'payoneer'"
                            @canConnected="savePayoneer = $event"
                        />

                        <wizard-payment-mpesa
                            v-if="selectedType === 'mpesa'"
                            :ref="selectedType"
                            :fields="fields[selectedType]"
                            :form-data="formData[selectedType]"
                            @update="onUpdate($event, selectedType)"
                        />

                        <footer class="profile-info__footer">
                            <custom-button
                                default
                                class="btn-base btn-main button"
                                :disabled="isSaveDisabled"
                                :loading="btnSaveLoader"
                                @on-btn-click="onSave()"
                            >
                                <span>
                                    Save
                                </span>
                            </custom-button>
                            <custom-button
                                class="btn-base btn-border button"
                                type="reset"
                                bordered
                                hover
                                @on-btn-click="$router.push({ name: 'profile_payment-method' })"
                            >
                                <span class="profile-info__reset-button-icon">
                                    Cancel
                                </span>
                            </custom-button>
                        </footer>
                    </div>
                </div>
            </div>
        </template>
    </main-layout>
</template>

<script>
import HeaderContent from '@/components/HeaderContent';
import WizardPaymentForm from '@/components/wizard/WizardPayment/components/WizardPaymentForm';
import WizardPaymentPayoneer from '@/components/wizard/WizardPayment/components/WizardPaymentPayoneer';
import MainLayout from '@/layouts/MainLayout';
import {
    getPaymentsListAvailable,
    getPaymentsListConnected,
    paymentWireCreateOrUpdate,
    paymentMpesaCreateOrUpdate,
    createPayoneer
} from '@/services/payments';

import { eventBus } from '@/helpers/event-bus';

import { mapWriterFields } from '@/store/modules/writer'
import { createNamespacedHelpers } from 'vuex';
import {
    ACTION_UPDATE_PAYMENT_DETAILS
} from '@/store/modules/writer/action-types'
import WizardPaymentMpesa from '@/components/wizard/WizardPayment/components/WizardPaymentMpesa.vue';

const { mapActions: mapWriterActions } = createNamespacedHelpers('writer')

export default {
    name: 'Index',
    components: {
        WizardPaymentForm,
        MainLayout,
        HeaderContent,
        WizardPaymentPayoneer,
        WizardPaymentMpesa
    },
    metaInfo: {
        title: 'Payment Method'
    },
    data() {
        return {
            type: 'payoneer',
            showModal: false,
            payments: null,
            activeTab: null,
            types: [],
            selectedType: 'wire',
            fields: {},
            connectedMethods: {},
            formData: {},
            loading: false,
            savePayoneer: false,
            btnSaveLoader: false
        }
    },
    computed: {
        ...mapWriterFields([
            'writer_data'
        ]),
        headerInfo() {
            return {
                title: 'Add New Method',
                className: 'header-content__account-setting',
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Dashboard',
                        url: '/cp/account'
                    },
                    {
                        title: 'Profile',
                        url: '/cp/profile'
                    },
                    {
                        title: 'Withdrawal Methods',
                        url: '/cp/profile/payment-method'
                    }
                ]
            }
        },
        isSaveDisabled() {
            return (this.selectedType === 'payoneer' && !this.savePayoneer) || this.btnSaveLoader
        }
    },
    async created() {
        this.loading = true
        await this.getTypes()
        if (this.$route.params.type) {
            this.selectedType = this.$route.params.type
        }
        if (this.$route.params.editId) {
            await this.getMethodForEdit()
        }
        this.loading = false
        if (this.$route.query.method) {
            this.selectMethod(this.$route.query.method)
        }
    },
    methods: {
        ...mapWriterActions([
            ACTION_UPDATE_PAYMENT_DETAILS
        ]),
        async getPaymentsListAvailable() {
            let data
            try {
                data = await getPaymentsListAvailable()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
            return data
        },
        async getPaymentsListConnected() {
            let data
            try {
                data = await getPaymentsListConnected()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
            return data
        },
        async paymentWireCreateOrUpdate(payload) {
            try {
                this.btnSaveLoader = true
                await paymentWireCreateOrUpdate(payload)
                this.$router.push({ name: 'profile_payment-method' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.btnSaveLoader = false
            }
        },
        async paymentMpesaCreateOrUpdate(payload) {
            try {
                this.btnSaveLoader = true
                await paymentMpesaCreateOrUpdate(payload)
                this.$router.push({ name: 'profile_payment-method' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.btnSaveLoader = false
            }
        },
        async createPayoneer() {
            try {
                this.btnSaveLoader = true
                await createPayoneer()
                this.$router.push({ name: 'profile_payment-method' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.btnSaveLoader = false
            }
        },
        async onSave() {
            if (this.preValidate()) {
                switch (this.selectedType) {
                case 'wire':
                    await this.paymentWireCreateOrUpdate(this.formData[this.selectedType])
                    break;
                case 'payoneer':
                    await this.createPayoneer()
                    break;
                case 'mpesa':
                    await this.paymentMpesaCreateOrUpdate(this.formData[this.selectedType])
                    break;
                default:
                    break;
                }
            }
        },
        async getMethodForEdit() {
            const response = await this.getPaymentsListConnected()
            const { editId, type } = this.$route.params
            this.formData[type] = response.list.find(({ id }) => id === editId)
        },
        async getTypes() {
            const { types, fields } = await this.getPaymentsListAvailable()
            this.types = [...types.map(({ key, title }) => ({
                text: title,
                value: key
            }))]
            this.fields = fields
        },
        onUpdate(payload, type) {
            this.$set(this.formData, type, {
                ...this.formData?.[type],
                ...payload
            })
        },
        preValidate() {
            let hasError = true
            if (this.selectedType !== 'payoneer') {
                this.$refs[this.selectedType].$v.$touch()
                hasError = !this.$refs[this.selectedType].$v.$anyError
            }
            return hasError
        },
        selectMethod(type) {
            this.selectedType = type
            this.$router.push({ query: { method: type } })
        }
    }
}
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 576px){
        .wizard__form .wizard__form {
            padding: 0;
            .wizard-payment__radio {
                flex-direction: column;
            }
        }
    }
</style>
